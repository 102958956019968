/* eslint-disable no-console */
import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IPropertiesResponse } from 'src/apis/types/responseBase';

interface TestAll extends IPropertiesResponse<ITestProps> {}
interface ITestProps {}

const API_URL =
  'https://insights.aks.timelog.com/api/v1/insights/enabled-widgets?siteId=530f2b1b-e35a-4707-b701-f8cb9d7c2abc';
const TEST_POC_KEY = 'TEST_POC_KEY';

const getTestPOC = (): Promise<TestAll> =>
  getFetch({
    path: API_URL,
    key: TEST_POC_KEY,
  });

export const useGetTestPOC = () => {
  const { data, ...restProps } = useQuery([TEST_POC_KEY], () => getTestPOC());

  console.log('here gan!!');
  console.log(data);
  console.log(restProps.status);
  console.log(restProps);

  return false;
};
